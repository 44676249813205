/* Preloader */
.preloader {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999999;
	-webkit-transition: 0.6s;
	-o-transition: 0.6s;
	transition: 0.6s;
	margin: 0 auto;
}

.preloader .preloader-circle {
	width: 100px;
	height: 100px;
	position: relative;
	border-style: solid;
	border-width: 3px;
	border-top-color: #1a9100;
	border-bottom-color: transparent;
	border-left-color: transparent;
	border-right-color: transparent;
	z-index: 10;
	border-radius: 50%;
	-webkit-box-shadow: 0 1px 5px 0 rgba(35, 181, 185, 0.15);
	box-shadow: 0 1px 5px 0 rgba(35, 181, 185, 0.15);
	background-color: #ffffff;
	-webkit-animation: zoom 2000ms infinite ease;
	animation: zoom 2000ms infinite ease;
	-webkit-transition: 0.6s;
	-o-transition: 0.6s;
	transition: 0.6s;
}

.preloader .preloader-img {
	position: absolute;
	top: 50%;
	z-index: 200;
	left: 0;
	right: 0;
	margin: 0 auto;
	text-align: center;
	display: inline-block;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	padding-top: 6px;
	-webkit-transition: 0.6s;
	-o-transition: 0.6s;
	transition: 0.6s;
}

.preloader .preloader-img img {
	max-width: 55px;
}

@-webkit-keyframes zoom {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: 0.6s;
		-o-transition: 0.6s;
		transition: 0.6s;
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
		-webkit-transition: 0.6s;
		-o-transition: 0.6s;
		transition: 0.6s;
	}
}

@keyframes zoom {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: 0.6s;
		-o-transition: 0.6s;
		transition: 0.6s;
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
		-webkit-transition: 0.6s;
		-o-transition: 0.6s;
		transition: 0.6s;
	}
}

/* BASIC */
body {
	font-family: "Lucida Grande", "Lucida Sans", Arial, sans-serif;
}
button {
	cursor: pointer;
}
.shipping-link {
	border: 1px solid #999;
	color: #1a9100;
}
.shipping-link:hover {
	color: #1a9100;
}
.shipping-link.disabled:hover {
	color: #6c757d !important;
}
ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}
a:hover {
	text-decoration: none;
}
.pt-60px {
	padding-top: 60px;
}
.pb-60px {
	padding-bottom: 60px;
}
.section-margin {
	margin: 35px 0;
}

/* NAVBAR */
.header_area {
	position: relative;
	width: 100%;
	z-index: 9999;
	transition: background 0.4s, all 0.3s linear;
	box-shadow: 0 10px 27px 3px rgba(221, 221, 221, 0.3);
}
.header_area .main_menu {
	position: fixed;
	width: 100%;
	top: -70px;
	left: 0;
	right: 0;
	background: #fff;
	-webkit-transform: translateY(70px);
	-moz-transform: translateY(70px);
	-ms-transform: translateY(70px);
	-o-transform: translateY(70px);
	transform: translateY(70px);
	-webkit-transition: all 0.3s ease 0;
	-moz-transition: all 0.3s ease 0;
	-o-transition: all 0.3s ease 0;
	transition: all 0.3s ease 0;
	box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.1);
}
.navbar .navbar-brand img {
	height: 50px;
	width: 140px;
}
.header_area .navbar .navbar-nav .nav-item {
	margin-right: 20px;
}

.header_area .navbar .navbar-nav .nav-item .nav-link {
	text-transform: capitalize;
	color: #222;
	display: inline-block;
	font-size: 0.95rem;
}
.header_area .navbar .cart-mobile {
	color: #222;
}
.header_area .navbar .cart-mobile .nav-shop__circle {
	font-size: 10px;
	display: inline-block;
	background: #1a9100;
	color: #fff;
	padding: 0 5px;
	border-radius: 50%;
	position: absolute;
	top: 1px;
	right: -18px;
}
.header_area .navbar .nav-link .nav-shop__circle {
	font-size: 10px;
	display: inline-block;
	background: #1a9100;
	color: #fff;
	padding: 0 5px;
	border-radius: 50%;
	position: absolute;
	top: 3px;
	right: 10px;
}
.header_area .navbar .navbar-nav .nav-item .nav-link:after {
	display: none;
}

.header_area .navbar .navbar-nav .nav-item:hover .nav-link {
	color: #1a9100;
}

.header_area .navbar .navbar-nav .nav-item .nav-link.active {
	color: #1a9100 !important;
}

.header_area .navbar .navbar-nav .nav-item.submenu {
	position: relative;
}
.header_area .navbar .navbar-nav .nav-item.submenu .dropdown-menu {
	border: none;
	border-radius: 0;
	box-shadow: none;
	background: #fff;
	margin: 0;
	padding: 0;
}
.header_area .navbar .navbar-nav .nav-item.submenu .dropdown-menu:before {
	content: "";
	width: 0;
	height: 0;
	position: absolute;
	right: 24px;
	top: 45px;
	z-index: 3;
	opacity: 0;
	transition: all 400ms linear;
	border-color: #eee transparent transparent;
	border-style: solid;
	border-width: 10px 10px 0;
}
.header_area
	.navbar
	.navbar-nav
	.nav-item.submenu
	.dropdown-menu
	.dropdown-item {
	display: block;
	float: none;
	margin: 0;
	border-bottom: 1px solid #ededed;
	margin-left: 0;
	transition: all 0.4s linear;
	line-height: 45px;
	color: #222;
	transition: all 150ms linear;
	padding: 0 30px;
	font-size: 0.9rem;
}
.header_area
	.navbar
	.navbar-nav
	.nav-item.submenu
	.dropdown-menu
	.dropdown-item:last-child {
	border-bottom: none;
}

.header_area
	.navbar
	.navbar-nav
	.nav-item.submenu
	.dropdown-menu
	.dropdown-item:hover {
	background: #1a9100;
	color: #fff;
}
.header_area
	.navbar
	.navbar-nav
	.nav-item.submenu
	.dropdown-menu
	.dropdown-item.active {
	background: #1a9100;
	color: #fff;
}
.header_area
	.navbar
	.navbar-nav
	.nav-item.submenu:hover
	.dropdown-menu
	.dropdown-item {
	margin-top: 0;
}
.header_area .navbar-nav .nav-link.btn-cart {
	border: 1px solid #eee;
	border-radius: 30px;
	padding: 5px 30px;
	transition: all 0.4s ease;
}
.header_area .navbar-nav .nav-link.btn-cart:hover {
	border-color: #1a9100;
	border-width: 2px;
	color: #222 !important;
}
.navbar-toggler {
	width: 37px;
	height: 27px;
	outline: 0 !important;
	cursor: pointer;
}
.navbar-toggler-bar {
	display: block;
	position: relative;
	width: 22px;
	height: 2px;
	border-radius: 1px;
	background: #000;
	margin-bottom: 7px;
}
.navbar-toggler-bar.middle-bar {
	width: 17px;
	transition: width 0.2s linear;
}
.navbar-toggler-bar.middle-bar:hover {
	width: 22px;
}
.main-section {
	position: relative;
	top: 70px;
}

/* HERO */
.hero-banner {
	background: #f1f6f7;
	position: relative;
	margin-bottom: 10px;
	top: 5px;
}
.carousel img {
	width: 100%;
}
.hero-banner__content h4 {
	color: #555;
	margin-bottom: 12px;
}
.hero-banner__content h1 {
	text-transform: uppercase;
	margin-bottom: 10px;
}
.hero-banner__content p {
	margin-bottom: 10px;
}

/* FILTER BAR */
.filter-bar {
	padding: 0 20px 10px;
	background: #f1f6f7;
	margin-bottom: 30px;
}
.sorting {
	margin-top: 10px;
	margin-right: 10px;
}
.filter-bar .input {
	border: 1px solid #eee;
	font-size: 14px;
	color: #999;
	width: 250px;
	height: 38px;
	padding-left: 15px;
}
.filter-bar .input:focus {
	outline: 0;
	box-shadow: none;
}
.filter-bar .input::placeholder {
	color: #999;
}
/* BUTTONS */
.show-btn {
	padding: 8px 15px;
	border: 1px solid #1a9100;
	color: #555;
	border-radius: 8px;
	font-size: 0.85rem;
}
.show-btn:hover {
	background: #1a9100;
	color: #fff;
}
.button {
	display: inline-block;
	border-radius: 8px;
	font-weight: normal;
	font-size: 0.9rem;
	background: #1a9100;
	color: #fff;
	transition: all 0.4s ease;
	padding: 8px 20px;
	text-decoration: none;
	border: 0;
	outline: 0 !important;
}
.button.disabled {
	background: #c38c85;
	cursor: none;
}
.button:hover {
	background: 1px solid #fd4f37;
	color: #fff;
}
.button-hero {
	padding: 12px 41px;
}
.button-account {
	background: inherit;
	color: #222 !important;
	border: 1px solid #1a9100;
}

/* ABOUT SCREEN */
.about_us {
	padding: 30px;
	border-radius: 15px;
}
.about_area {
	font-size: 0.9rem;
	text-align: justify;
}
.about_area ul {
	list-style-type: square;
	margin: 10px 40px;
}

/* Section Trending */
.section-intro {
	margin-bottom: 30px;
}
.section-intro h5 {
	font-size: 1.2rem;
	font-weight: normal;
}
.section-intro p {
	margin-bottom: 3px;
}
.section-intro__style {
	border-bottom: 2px solid #1a9100;
	padding-bottom: 8px;
}
.section-margin.calc-60px {
	margin: 45px 0 60px;
}
.card-product {
	margin-bottom: 30px;
}
.card-product:hover {
	box-shadow: 0 1px 5px 0 rgba(35, 181, 185, 0.15);
}
.card-product .card-product p {
	margin-bottom: 0;
}
.card-product__img {
	position: relative;
	padding: 5px;
}
.card-product__imgOverlay {
	background: rgba(255, 255, 255, 0.5);
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	transform: translateY(30px);
	opacity: 0;
	z-index: -1;
	transition: all 0.48s ease;
	padding: 30px 5px;
}
.card-product__imgOverlay li {
	display: inline-block;
}
.card-product__imgOverlay li button {
	border: 0;
	border-radius: 0;
	background: #1a9100;
	padding: 7px 12px;
}
.card-product__imgOverlay li button i {
	font-size: 15px;
	color: #fff;
	vertical-align: middle;
}
.card-product__imgOverlay li button span {
	font-size: 15px;
	color: #fff;
	vertical-align: middle;
}
.card-product__imgOverlay li button:hover {
	background: #1a9100;
}
.card-product__imgOverlay li:not(:last-child) {
	margin-right: 5px;
}
.card-product__title a {
	color: #222;
}
.card-product__price {
	font-size: 18px;
	font-weight: 500;
}
.card-product:hover .card-product__imgOverlay {
	opacity: 1;
	z-index: 1;
	transform: translateY(0);
}
.card-product:hover .card-product__title a {
	color: #1a9100;
}

/* Offer Page */

/* SEARCH PAGE */
.search-area {
	margin-bottom: 50px !important;
}
.search-area .form-control {
	border-radius: 10px;
	height: 50px;
	width: 100%;
	padding-left: 20px;
	font-size: 14px;
	border-color: #eee;
	margin-bottom: 10px;
}
.search-area .button {
	height: 50px;
	width: 100%;
	margin-left: 5px;
	margin-bottom: 10px;
}
.search-area .form-control::placeholder {
	color: #999;
}
.search-area .form-control:focus {
	outline: 0;
	box-shadow: none;
}

/* SUBSCRIBE SECTION */
.subscribe {
	background: #fff;
	border-radius: 5px;
	box-shadow: 0 -5px 20px 0 rgba(56, 74, 235, 0.1);
	padding: 45px 10px;
}
.subscribe__title {
	font-size: 28px;
	font-weight: 500;
	text-transform: uppercase;
	margin-bottom: 16px;
}
.subscribe-form .form-group {
	margin-bottom: 0;
	position: relative;
}
.subscribe-form .form-group .info {
	position: absolute;
	top: 50px;
	left: 10px;
	color: #384aeb;
}
.subscribe-form .form-control {
	border-radius: 30px;
	height: 50px;
	padding-left: 20px;
	font-size: 14px;
	border-color: #eee;
}
.subscribe-form .form-control::placeholder {
	color: #999;
}
.subscribe-form .form-control:focus {
	outline: 0;
	box-shadow: none;
}
.subscribe-form button {
	margin-left: 15px;
}
.subscribe-position {
	margin-bottom: 20px;
}

/* FOOTER */
.footer-area {
	background: #002347;
	padding: 40px 0;
}
.footer .socials {
	margin-top: 8px;
}
.footer .socials a {
	color: #fff;
	margin-right: 8px;
	font-size: 1rem;
	border: 1px solid #eee;
	padding: 5px 7px;
	border-radius: 50%;
}
.single-footer-widget p {
	margin-bottom: 15px;
}
.single-footer-widget p:last-child {
	margin-bottom: 0;
}

.single-footer-widget .sm-head {
	color: #fff;
	margin-bottom: 0;
}
.single-footer-widget .sm-head span {
	position: relative;
	margin-left: -40px;
	margin-right: 10px;
	display: inline-block;
	width: 28px;
	height: 28px;
	line-height: 28px;
	text-align: center;
	background: #1a9100;
	color: #fff;
	border-radius: 20px;
}
.tp_widgets .list li {
	margin-bottom: 12px;
}
.tp_widgets .list li a {
	color: #7b838a;
	font-family: roboto, sans-serif;
}
.tp_widgets .list li a:hover {
	color: #1a9100;
}
.tp_widgets .list li:last-child {
	margin-bottom: 0;
}
.tp_widgets p {
	color: #7b838a;
}
.footer_title {
	font-size: 18px;
	line-height: 24px;
	font-weight: 500;
	font-family: oswald, sans-serif;
	text-transform: capitalize;
	color: #fff;
	margin-bottom: 28px;
}
.footer_title.large_title {
	font-size: 36px;
	font-weight: 600;
}
.footer-bottom {
	background: rgba(0, 35, 71, 0.980392);
	padding: 32px;
}
.footer-bottom .footer-text {
	color: #fff;
	margin-bottom: 0;
}
.footer-bottom .footer-text a {
	color: #1a9100;
	opacity: 1;
}
.footer-only {
	padding-top: 60px !important;
	padding-bottom: 60px !important;
}

/* LOGIN SCREEN */
.login_box_area .login_box_img {
	height: 350px;
	position: relative;
}
.login_box_area .login_box_img img {
	width: 100%;
}
.login_box_area .login_box_img:before {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	content: "";
	background: radial-gradient(#fff, #ffd6d6);
	opacity: 0.9;
}
.login_box_area .login_box_img .hover {
	position: absolute;
	top: 50%;
	left: 0;
	text-align: center;
	width: 100%;
	transform: translateY(-50%);
}
.login_box_area .login_box_img .hover h4 {
	font-size: 24px;
	color: #222;
	font-weight: 500;
	margin-bottom: 15px;
}
.login_box_area .login_box_img .hover p {
	max-width: 390px;
	color: #222;
	margin: 0 auto 28px;
}
.login_box_area .login_box_img .hover .primary-btn {
	border-radius: 0;
	line-height: 38px;
	text-transform: uppercase;
}
.login_box_area .login_box_img .hover .primary-btn:hover:before {
	left: 260px;
}
.login_form_inner {
	box-shadow: 0 10px 40px 0 rgba(56, 74, 235, 0.1);
	height: 100%;
	text-align: center;
	padding: 30px 0;
}
.login_form_inner h3 {
	color: #222;
	text-transform: uppercase;
	font-size: 18px;
	margin-bottom: 75px;
}
.login_form_inner .login_form {
	max-width: 385px;
	margin: auto;
}
.login_form_inner.reg_form {
	padding-top: 60px;
}
.login_form_inner.reg_form h3 {
	margin-bottom: 70px;
}
.login_form .form-group {
	margin-bottom: 22px;
}
.login_form .form-group input {
	height: 40px;
	border: none;
	border-bottom: 1px solid #ccc;
	border-radius: 0;
	outline: none;
	box-shadow: none;
	font-size: 14px;
	color: #999;
}
.login_form .form-group input.placeholder {
	color: #999;
}
.login_form .form-group input:-moz-placeholder {
	color: #999;
}
.login_form .form-group input::-moz-placeholder {
	color: #999;
}
.login_form .form-group input::-webkit-input-placeholder {
	color: #999;
}
.login_form .form-group .creat_account {
	margin-top: 10px;
	font-size: 14px;
	text-align: left;
}
.login_form .form-group .creat_account input {
	height: auto;
	margin-right: 10px;
	position: relative;
	top: 2px;
}
.login_form .form-group .primary-btn {
	display: block;
	border-radius: 0;
	line-height: 38px;
	width: 100%;
	text-transform: uppercase;
	border: none;
}
.login_form .form-group .primary-btn:hover:before {
	left: 370px;
}
.login_form .form-group a {
	font-size: 14px;
	color: #777;
	margin-top: 20px;
	display: block;
}

/* SINGLE PRODUCT SCREEN */
.s_product_text h2 {
	font-size: 24px;
	font-weight: 700;
	margin-bottom: 10px;
}
.s_product_text .list li {
	margin-bottom: 5px;
}
.s_product_text .list li span {
	width: 90px;
	display: inline-block;
	color: #555;
	font-weight: 600;
}
.s_product_text p {
	padding-top: 20px;
	border-top: 1px dotted #eee;
	border-bottom: 1px dotted #eee;
	margin-top: 20px;
	padding-bottom: 26px;
	margin-bottom: 0;
}
.product_count {
	display: inline-block;
	position: relative;
	margin-bottom: 24px;
	margin-top: 15px;
}
.product_count label {
	font-size: 14px;
	color: #777;
	font-weight: 400;
	padding-right: 10px;
}
.product_count select {
	width: 76px;
	border: 1px solid #eee;
	border-radius: 3px;
	padding-left: 10px;
	outline: 0;
}
.product_description_area {
	padding-bottom: 100px;
	margin-top: 60px;
}
.product_description_area .total_rate .box_total {
	background: #f1f6f7;
	text-align: center;
	padding-top: 20px;
	padding-bottom: 20px;
}
.product_description_area .total_rate .box_total h4 {
	font-size: 48px;
	font-weight: 700;
	color: #1a9100;
}
.product_description_area .total_rate .box_total h5 {
	color: #333;
	margin-bottom: 0;
	font-size: 24px;
}
.product_description_area .total_rate .box_total h6 {
	margin-bottom: 0;
	font-size: 14px;
	color: #555;
	font-weight: 400;
}
.review_item {
	margin-bottom: 10px;
	margin-top: 20px;
	font-size: 0.9rem;
}
.review_item:last-child {
	margin-bottom: 0;
}
.review_item .media {
	position: relative;
}
.review_item .media .media-body {
	vertical-align: middle;
	align-self: center;
}
.review_item .media .media-body h4 {
	font-size: 16px;
	color: #222;
	margin-bottom: 4px;
}
.review_item .media .media-body i {
	color: #fbd600;
}
.review_item .media .media-body h5 {
	font-size: 13px;
	font-weight: 400;
	color: #777;
}
.review_item p {
	padding-top: 10px;
	margin-bottom: 0;
}
.review_box h4 {
	font-size: 20px;
	color: #222;
	margin-bottom: 20px;
}
.review_box p {
	margin-bottom: 0;
	color: #555;
	display: inline-block;
}
.border-form {
	padding: 30px 50px;
	border: 1px solid rgba(0, 0, 0, 0.1);
}
.border-category {
	padding: 20px;
	border: 2px dotted rgba(0, 0, 0, 0.1);
}
.border-category h2 {
	margin: 0;
	padding: 0;
	font-size: 1.8rem;
}
.comment-form label {
	font-size: 0.9rem;
}
.comment-form label span {
	color: #fd4f37;
	font-weight: bolder;
}
.comment-form .form-control {
	background: #fff;
	border-radius: 0;
	width: 100%;
	font-size: 14px;
	color: #777;
	border: 1px solid #eee;
	padding: 8px 20px;
}
.comment-form .form-control:focus {
	box-shadow: none;
	border: 1px solid #eee;
}
.comment-form .form-control::placeholder {
	color: #777;
}
.comment-form textarea.form-control {
	height: 140px;
	resize: none;
}
.style_field {
	border: 1px solid gray;
	min-height: 6rem;
}

/* CART PAGE */
.cart_area .table {
	font-size: 0.9rem;
}
.cart_area .table th {
	border-color: #eee;
	padding: 0.75rem 1.5rem;
}
.cart_area .table td {
	border-color: #eee;
	padding: 0.75rem 1.5rem;
}
.cart_area .table thead tr th {
	border-top: 0;
	font-size: 16px;
	font-weight: 500;
	color: #222;
	border-bottom: 0 !important;
}
.cart_area .table tbody tr td {
	padding-top: 30px;
	padding-bottom: 30px;
	vertical-align: middle;
	align-self: center;
}
.cart_area .table tbody tr td .media .d-flex {
	padding-right: 30px;
}
.cart_area .table tbody tr td .media .d-flex img {
	border-radius: 3px;
	width: 50px;
	height: 50px;
}
.cart_area .table tbody tr select {
	width: 76px;
	border: 1px solid #eee;
	border-radius: 3px;
	padding-left: 10px;
	outline: 0;
}
.cart_area .table tbody tr button {
	border: 0;
	outline: 0 !important;
	color: #1a9100;
}
.order_box {
	background: #e8f0f2;
	padding: 30px;
}
.order_box h2 {
	border-bottom: 1px solid #ddd;
	font-size: 18px;
	font-weight: 500;
	color: #222;
	padding-bottom: 15px;
}
.order_box .list li strong {
	font-size: 14px;
	color: #777;
	font-weight: 400;
	border-bottom: 1px solid #eee;
	display: block;
	line-height: 42px;
}
.order_box .list li strong h4 {
	font-size: 15px;
	margin-top: 15px;
}
.order_box .list li strong span {
	float: right;
}
.order_box .list li strong .middle {
	float: none;
	width: 50px;
	text-align: right;
	display: inline-block;
	margin-left: 30px;
	color: #222;
}
.order_box .list li:nth-child(4) strong .middle {
	margin-left: 48px;
}
.order_box .list_2 li strong {
	text-transform: uppercase;
	color: #222;
	font-weight: 500;
}
.order_box .list_2 li strong span {
	color: #777;
	text-transform: capitalize;
}
.order_box .list_2 li:last-child strong span {
	color: #222;
}
.order_box .payment_item p {
	background: #fff;
	font-size: 12px;
	padding: 15px 20px;
}
.order_box .creat_account label {
	padding-left: 10px;
	font-size: 12px;
	color: #222;
}
.order_box .creat_account a {
	font-size: 12px;
	color: #384aeb;
}
.order_box .button-paypal {
	padding: 8px 30px;
	background: #384aeb;
	margin-top: 10px;
}
.order_box .button-paypal:hover {
	background: #384aeb;
	color: #fff;
	border: none;
}
.paystack-button {
	cursor: pointer;
	text-align: center;
	font-size: 10px;
	letter-spacing: 0.1rem;
	text-transform: uppercase;
	background-color: #908585;
	font-weight: bold;
	color: #e0eafc;
	border: none;
	padding: 12px 35px;
	border-radius: 5px;
	height: 45px;
	margin-top: 40px;
}

.paystack-button:hover {
	background-color: #908585;
}

.paystack-button:focus {
	outline: none;
}
.store-table {
	font-size: 0.9rem;
}
.profile_container {
	border: 2px solid rgba(0, 0, 0, 0.1);
	padding: 30px;
}

/* CATEGORIES SEARCH */
.categories-search {
	font-size: 0.9rem;
	padding-top: 40px;
}
.categories-search p {
	text-align: center;
	color: #222;
}
.categories-search img {
	width: 100%;
	height: 150px;
}

/* FEATURES */
.features i {
	font-size: 2rem;
	color: #1a9100;
}
.features .box {
	padding: 20px;
	border: 1px solid rgba(0, 0, 0, 0.2);
}
.features .box h6 {
	font-size: 0.9rem;
	margin-top: 7px;
}
.features .box p {
	font-size: 0.8rem;
}

/* Whatsapp Icon */
.whatsapp {
	position: fixed;
	background: rgb(0, 128, 0);
	color: #fff;
	width: 55px;
	height: 55px;
	text-align: center;
	line-height: 1;
	font-size: 16px;
	border-radius: 50%;
	right: 15px;
	bottom: 15px;
	transition: background 0.5s;
	z-index: 11;
}
.whatsapp i {
	padding-top: 8px;
	color: #ffffff;
	font-size: 2.5rem;
}

@media (max-width: 576px) {
	.card-product img {
		height: 120px;
		width: 100%;
	}
	.card-product h6 {
		font-size: 0.9rem;
	}
	.subscribe .subscribe-form {
		display: block;
	}
	.hero-banner__content {
		padding-bottom: 50px;
	}
	.footer-bottom {
		text-align: center;
	}
	.footer-bottom .footer-social {
		text-align: center;
		margin-top: 15px;
	}
	.footer-area {
		padding: 70px 0;
	}
	.single-footer-widget .sub-btn {
		right: 0;
	}
	.card-row {
		padding: 0 1%;
	}
	.categories-search img {
		height: 100px;
	}
}

@media (min-width: 575px) {
	.hero-banner::before {
		width: 20%;
	}
}
@media (min-width: 767px) {
	.hero-banner__content h1 {
		font-size: 30px;
		margin-bottom: 25px;
	}

	.hero-banner__content p {
		margin-bottom: 40px;
	}
}
@media (min-width: 800px) {
	.search-area .form-control {
		min-width: 400px;
	}
	.subscribe-form .form-control {
		min-width: 380px;
	}

	.button-subscribe {
		font-size: 15px;
		padding: 12px 35px;
	}
	.section-margin {
		margin: 70px 0;
	}
	.card-prod {
		width: 200px;
		height: 200px;
		margin: auto;
		overflow: hidden;
	}
	.card-prod img {
		height: auto;
		width: 100%;
	}
}

@media (max-width: 1200px) {
	.header_area .navbar-nav.nav-second {
		display: flex;
		flex-direction: row;
		margin: 10px 0;
	}
	.header_area .navbar .navbar-nav .nav-item {
		margin-top: 10px;
	}
	.footer-area .col-sm-6 {
		margin-bottom: 30px;
	}
}

@media (min-width: 991px) {
	.hero-banner {
		z-index: 111;
	}
	.hero-banner__content h1 {
		font-size: 50px;
	}
	.login_box_area .login_box_img {
		margin-right: -30px;
	}
}

@media (min-width: 1000px) {
	.section-margin {
		margin: 120px 0;
	}
	.hero-banner__content {
		max-width: 495px;
	}
	.subscribe {
		padding: 85px 10px;
	}
	.subscribe__title {
		font-size: 36px;
	}
	.subscribe-position {
		position: relative;
		top: 100px;
		margin-bottom: 0;
	}
	.login_box_area .login_box_img {
		height: 610px;
	}
	.login_form_inner {
		padding-top: 115px;
		padding-bottom: 0;
	}
	.footer-area {
		padding: 160px 0 120px;
	}
	.footer-only {
		padding-top: 115px !important;
		padding-bottom: 115px !important;
	}
}
@media (min-width: 1200px) {
	.header_area .navbar .navbar-nav .nav-item.submenu .dropdown-menu {
		position: absolute;
		top: 120%;
		left: 0;
		min-width: 200px;
		text-align: left;
		opacity: 0;
		transition: all 300ms ease-in;
		visibility: hidden;
		display: block;
		border: none;
		border-radius: 0;
		padding: 0;
	}

	.header_area .navbar .navbar-nav .nav-item.submenu:hover .dropdown-menu {
		visibility: visible;
		opacity: 1;
		top: 100%;
	}
}

.mtt {
	margin-top: 7rem !important;
}
